export default function () {
  const i18n = useI18n()
  const routeQuery = useRouteQuery('lang')
  const availableLocales = i18n.locales.value.filter(lang => lang.code !== 'de_marketing_environment')

  const locale = computed<SupportedLocale>(() => {
    const locale = typeof routeQuery.value === 'string' ? routeQuery.value : i18n.locale.value
    if (availableLocales.some(l => l.code === locale)) {
      return locale as SupportedLocale
    }

    return 'de'
  })

  return { locale, availableLocales }
}
